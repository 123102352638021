import React, { useState, useEffect} from 'react';
import styled from 'styled-components';

import gsap from 'gsap'
 
const Container = styled.div`

`

export default function HoverGsap ({children, id, effect = {scale: 1.1}, noEffect = {scale: 1}, duration = {duration: .2}, className}) {
  
  const [hover, setHover] = useState(false)

  const hoverHandler = e => {
    setHover(hover => !hover)
  }

  useEffect(() => {
    if (hover) {
      gsap.to(`#${id}`, {...effect, ...duration})
    } else {
      gsap.to(`#${id}`, {...noEffect, ...duration})
    }
  }, [hover])

 return (
  <Container className={className} id={id} onMouseOver={hoverHandler} onMouseOut={hoverHandler}>
    {children}
  </Container>
 )
}