import React, { useEffect, useRef} from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import RowWrapper from '../utils/rowWrapper';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import Header from "../components/header-section";
import ImageSection from '../components/image-section';
import BracketRight from "../images/bracket-right.png";
import BracketLeft from "../images/bracket-left.png";

import HoverGsap from '../utils/hover-gsap';

import Contact from '../components/contact-button';


gsap.registerPlugin(ScrollTrigger)

const Main = styled.div`

margin-top: 8rem;
overflow-x: hidden;

h1 {
  font-size: 6rem;
  color: ${props => props.theme.colors.color};
  text-align: center;

  
  @media ${props => props.theme.breaks.down('md')} {
    font-size: 5rem;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 3rem;
    width: 80%;
    margin: 0 auto;
  }
}

h2 {
  font-size: 3rem;
  color: ${props => props.theme.colors.color};
  text-align: center;
  font-family: "Museo Sans Rounded 500", sans-serif;
  line-height: 4rem;
}

@media ${props => props.theme.breaks.down('sm')} {
  margin-top: 6rem;

  h2 {
    font-size: 2rem;
    line-height: 3rem;
    width: 85%;
    margin: 0 auto;
  }
}

`

const ScrollBox = styled.div`

@media ${props => props.theme.breaks.down('sm')} {
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
    display: none;
  }
  }
`

const BoxContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10rem auto 5rem auto;

  &.second-box {
    margin: 5rem auto 7rem auto;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    width: max-content;
  }
`

const Box = styled(HoverGsap)`
  border: 1px solid ${props => props.theme.colors.color};
  padding:  2rem;
  max-width: 25rem;
  margin: 0 2.5rem;

  p {
    font-family: "Museo Sans Rounded 300", sans-serif;
    font-size: 2.5rem;
    text-align: center;
    /* transition: transform .2s ease-in; */
  }

  /* p:hover {
    transform: scale(1.03);
  } */

  @media ${props => props.theme.breaks.down('sm')} {
    padding: 2.5rem;
    p {
      font-size: 2rem;
    }
  }
`

const Paragraph = styled.p`
  width: 70%;
  max-width: 100rem;
  margin: 5rem auto 5rem auto;
  text-align: center;
  font-size: 2.6rem;
  line-height: 3.2rem;

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2rem;
    width: 90%;
    margin-top: 2rem;
  }
`

const BoxHalf = styled.div`
  border: 1px solid ${props => props.theme.colors.color};
  width: 70%;
  margin: 0 auto;
  /* display: flex;
  align-items: center; */
  position: relative;
  margin-bottom: 8rem;
  height: 23rem;

  p {
    text-transform: uppercase;
    font-family: "Museo Sans Rounded 300", sans-serif;
    font-size: 2.6rem;
    text-align: center;
    margin: 0 auto;
    padding: 3rem 6rem;
    line-height: 3.4rem;
    max-width: 45rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 95%;
    height: 18rem;
     p {
      font-size: 1.8rem;
      line-height: 2.2rem;
      padding: 3rem 1rem;
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    width: 95%;
    height: 18rem;
     p {
      font-size: 1.4rem;
      line-height: 1.8rem;
      padding: 3rem 1rem;
    }
  }
  
`
const BoxBlue = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: ${props => props.theme.colors.color};
  z-index: 10;

  p {
    color: ${props => props.theme.colors.body};
  }  
`

const BoxOutline = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 5;


  p {
    color: ${props => props.theme.colors.color};
    padding-top: 7rem;
  }
`

const Point = styled.h3`
  font-size: 3.5rem;
  color: ${props => props.theme.colors.color};
  text-align: center;
  line-height: 2.8rem;
  margin-bottom: 10rem;

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2.5rem;
  }
`
const Sub = styled.span`
  font-size: 2rem;
  text-transform: none;

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 1.6rem;
  }
`

const PreCheck = styled.h6`
  font-family: "Museo Sans Rounded 500", sans-serif;
  text-transform: uppercase;
  font-size: 2.8rem;
  text-align: center;
  font-weight: normal;
  margin-bottom: 2rem;

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 1.6rem;
    letter-spacing: 1px;
    margin-bottom: 4rem;
  }
`

const Checklist = styled.div`
  width: 70%;
  margin: 0 auto 8rem auto;
  height: 25rem;
  text-align: center;
  position: relative;

  .bracket-left, .bracket-right {
    position: absolute;
    height: 25rem;

    img {
      opacity: .3;
      height: 100%;
    }
  }

  .bracket-left {
    right: 0;
  }

  .bracket-right {
    left: 0;
  }

  ul {
    position: relative;
    height: 100%;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    height: 9rem;
    width: 75%;
    .bracket-left, .bracket-right {
      height: 9rem;
    }
  }
`

const Item = styled.li`

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: ${props => props.visible ? 1 : 0};

  
  p {
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.colors.color};
    font-size: 3rem;
    font-family: "Museo Sans Rounded 1000", sans-serif;
  }

  span {
    display: block;
    font-size: 2.8rem;
    font-family: "Museo Sans Rounded 300", sans-serif;
    text-transform: none;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    top: 40%;

    p {
      font-size: 2rem;
    }

    span {
      font-size: 1.8rem;
      margin-bottom: .5rem;
    }
  }
  
`

const Footer = styled.div`
  margin-bottom: 2.5rem;
`
 
const Complete = ({ data }) => {

  const list = useRef()
  const br = useRef()
  const bl = useRef()
  const fb = useRef()
  const sb = useRef() 
  const bh = useRef() // Box Half
  const bb = useRef() // Box Blue
  const bo = useRef() // Box Outline

  const image = getImage(data.file)

  useEffect(() => {

    const boxStagger1 = gsap.timeline({
      scrollTrigger: {
        trigger: fb.current,
        start: "top 70%",
        toggleActions: "play none none reverse"
      }
    })

    boxStagger1.fromTo(".first-box div", {autoAlpha: 0, y: 200}, {autoAlpha: 1, y: 0, duration: 1, stagger: .2, ease: "back"})

    const boxStagger2 = gsap.timeline({
      scrollTrigger: {
        trigger: sb.current,
        start: "top 70%",
        toggleActions: "play none none reverse"
      }
    })

    boxStagger2.fromTo(".second-box div", {autoAlpha: 0, y: 100}, {autoAlpha: 1, y: 0, duration: 1, stagger: .2, ease: "back"})

    let listItems = gsap.utils.toArray(".list-item")

    const delay = 2;
    const duration = .3
    const scale = .5

    const master = gsap.timeline({
      repeat: -1,
      scrollTrigger: {
        trigger: list.current,
        start: "top 100%",
        toggleActions: "play none none none"
      }
    });

    master.fromTo(listItems[0], {autoAlpha: 0, scale: scale}, {autoAlpha: 1, scale: 1, duration: duration, ease: "ease.in"})

    for (let i = 1; i < listItems.length; i++) {
      let tlnew = gsap.timeline()

      tlnew.addLabel("start-fade")
      .to(br.current, {left: "1rem", scale: 1.1, delay: delay}, "start-fade")
      .to(bl.current, {right: "1rem", scale: 1.1, delay: delay}, "start-fade")
      .to(listItems[i - 1], {autoAlpha: 0, delay: delay}, "start-fade")
      .addLabel("fade-in")
      .to(br.current, {left: 0, scale: 1, duration: duration}, "fade-in")
      .to(bl.current, {right: 0, scale: 1, duration: duration}, "fade-in")
      .fromTo(listItems[i], {autoAlpha: 0, scale: scale}, {autoAlpha: 1, scale: 1, duration: duration, ease: "ease.in"}, "fade-in")

      master.add(tlnew)
    }

    master.addLabel("fade-last")
    .to(br.current, {left: "1rem", scale: 1.1, delay: delay}, "fade-last")
    .to(bl.current, {right: "1rem", scale: 1.1, delay: delay}, "fade-last")
    .to(listItems[listItems.length - 1], {autoAlpha: 0, scale: scale, delay: delay, ease: "ease.in"}, "fade-last")
    .addLabel("fade-final")
    .to(br.current, {left: 0, scale: 1, duration: duration, ease: "ease.in"}, "fade-final")
    .to(bl.current, {right: 0, scale: 1, duration: duration, ease: "ease.in"}, "fade-final")

    const boxHalf = gsap.timeline({
      scrollTrigger: {
        trigger: bh.current,
        start: "top 60%",
        toggleActions: "play none none reverse"
      }
    })

    boxHalf.addLabel("start-bh")
    .fromTo(bb.current, {width: "100%"}, {width: "50%", duration: 1}, "start-bh")
    .fromTo(bo.current, {width: "100%"}, {width: "50%", duration: 1}, "start-bh")
    
  }, [])

 
 return (
  <Layout pageTitle="Create A Space - Complete">
    <RowWrapper>
      <Header img={image} />
      <Main>
        <h1>WHAT IS COMPLETION?</h1>

        <ScrollBox>
          <BoxContainer ref={fb} className="first-box">
            <Box id="point-one">
              <p>It’s one of your most-used spaces.</p>
            </Box>
            <Box id="point-two">
              <p>It’s one of the first things you see in the morning.</p>
            </Box>
            <Box id="point-three">
              <p>It’s where you get ready for the rest of the day.
                </p>
            </Box>
          </BoxContainer>
        </ScrollBox>

        <h2 className="try">So why shouldn’t you love your closet?</h2>

        <ScrollBox>
          <BoxContainer ref={sb} className="second-box">
            <Box id="point-four">
              <p>It’s where you hang your most beautiful clothing.</p>
            </Box>
            <Box id="point-five">
              <p>It’s where you need to find things easily and quickly.</p>
            </Box>
            <Box id="point-six">
              <p>It’s a place for some of your most personal items.</p>
            </Box>
          </BoxContainer>
        </ScrollBox>


        <h2 style={{marginBottom: "8rem"}}>
        So why shouldn’t your closet look <br /> as amazing as the rest of your home?
        </h2>

        <ImageSection img={image} />

        <Paragraph>
        Completion is not seeing cracks or dust or spaces where they shouldn’t be in your brand-new closet. When you open your closet, you’ll notice how much it’s designed to make you feel the wow. When you use your closet, you’ll feel how much it’s designed to fulfill your every closet need.
        </Paragraph>

        <BoxHalf ref={bh}>

          <BoxBlue ref={bb}>
            <p>
            You’ve got the<br /> home, the <br />clothes, the <br />furniture that all look incredible.
            </p>
          </BoxBlue>

          <BoxOutline ref={bo}>
            <p>
            Don’t leave your closet behind.
            </p>
          </BoxOutline>

        </BoxHalf>

        <Point>
          The Create A Space Promise<br />
        <Sub>Closets that were meant to be.</Sub>
        </Point>

        <PreCheck>
          The Completion Checklist
        </PreCheck>

        <Checklist ref={list}>
        <div className="bracket-right" ref={br} ><img src={BracketRight} alt="" /></div>
        <div className="bracket-left" ref={bl} ><img src={BracketLeft} alt="" /></div>
          <ul>
            <Item className="list-item">
              <p>
                <span>Your Needs:</span>
                Completely satisfied.
              </p>
            </Item>
            <Item className="list-item">
              <p>
                <span>Your Expectations:</span>
                Completely exceeded.
              </p>
            </Item>
            <Item className="list-item">
              <p>
                <span>Your Dreams:</span>
                Completely realized.
              </p>
            </Item>
            <Item className="list-item">
              <p>
                <span>Your Closets:</span>
                Completely perfect.
              </p>
            </Item>
            <Item className="list-item">
              <p>
                <span>Your Home:</span>
                Completely complete.
              </p>
            </Item>
          </ul>
        </Checklist>

        <Footer>
          <Contact />
        </Footer>

      </Main>
    </RowWrapper>
  </Layout>
 )
}

export default Complete;


export const query = graphql`
query CompleteQuery {
  file(relativePath: { eq: "header-complete.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1800, height: 1000, quality: 100)
      }
    }
  }
`