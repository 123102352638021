import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image"

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger';


import BracketRight from "../images/bracket-right.png";
import BracketLeft from "../images/bracket-left.png";

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div`
`

const Image = styled.div`
  height: calc(100vh / 1.8);
  max-height: 50rem;
  width: 70%;
  margin: 0 auto;
  position: relative;

  .img-section-container {
    height: 100%;
    max-height: 50rem;
  }

  .bracket-left, .bracket-right {
    position: absolute;
    /* height: 30rem; */
    width: 10rem;
    transform: translateY(-50%);

    img {
      width: 100%;
    }
  }

  .bracket-right {
    top: 50%;
    left: -6rem;
  }

  .bracket-left {
    top: 50%;
    right: -6rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    height: 30rem;
    width: 85%;
    .bracket-left, .bracket-right {
      width: 6rem;
    }

    .bracket-right {
    left: -3.5rem;
  }

  .bracket-left {
    right: -3.5rem;
  }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    height: 20rem;
    width: 85%;
    .bracket-left, .bracket-right {
      width: 4rem;
    }

    .bracket-right {
    left: -2.5rem;
  }

  .bracket-left {
    right: -2.5rem;
  }
  }
`

 const ImageSection = ({img}) => {

 const br = useRef()
 const bl = useRef()

 useEffect(() => {

  let lPosStart = window.innerWidth < 600 ? "40%" : "42%";
  let rPosStart = window.innerWidth < 600 ? "60%" : "58%";
  let lPosEnd = window.innerWidth < 600 ? "-2.5rem" : window.innerWidth < 900 ? "-3.5rem" : "-6rem";
  let rPosEnd = window.innerWidth < 600 ? "-2.5rem" : window.innerWidth < 900 ? "-3.5rem" : "-6rem";

  let start = window.innerWidth < 600 ? "40%" : "50%";

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".img-section-container",
      start: `top ${start}`,
      toggleActions: "play none none reverse"
    }
  })

  tl.addLabel("spread")
  .fromTo(br.current, {left: lPosStart, translateX: "-50%"}, {left: lPosEnd, translateX: "0", duration: 1}, "spread")
  .fromTo(bl.current, {left: rPosStart, translateX: "-50%"}, {left: "auto", right: rPosEnd, translateX: "0", duration: 1}, "spread")
  .fromTo(".img-section-container", {autoAlpha: 0}, {autoAlpha: 1, duration: 2}, "-=1")
 }, [])

 
 return (
  <Container>
    <Image>
      <GatsbyImage image={img} imgClassName="header-img" className="img-section-container" alt="Closets" />
      <div className="bracket-right" ref={br} ><img src={BracketRight} alt="" /></div>
      <div className="bracket-left" ref={bl} ><img src={BracketLeft} alt="" /></div>
    </Image>
  </Container>
 )
}

export default ImageSection;